import React from 'react';

import Slider from 'infinite-react-carousel';
import Fade from 'react-reveal/Fade';

import { Col, Grid, Row } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Body, H3, H4 } from '@atomic/atm.typography';

import {
  TestimonialsWrapperStyled,
  TestimonialsItemWrapperStyled,
  TestimonialsItemImageStyled,
  TestimonialsItemContentStyled
} from './testimonials.section.style';

interface TestimonialsSectionProps {
  // eslint-disable-next-line no-undef
  data: readonly GatsbyTypes.MarkdownRemarkFrontmatterTestimonials[];
}

export const TestimonialsSection: React.FC<TestimonialsSectionProps> = ({
  data
}) => (
  <Fade bottom distance="10vw" duration={600}>
    <Grid id="testimonials">
      <Row hAlign="center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} rowLike>
          <TestimonialsWrapperStyled>
            <Slider
              dots
              centerMode
              arrows={false}
              autoplay
              autoplaySpeed={5000}
              dotsClass="carousel-dots"
              className="sliderStyled"
              swipe={false}
              pauseOnHover={false}
            >
              {data.map(({ profile, author, testimonial, office }) => (
                <TestimonialsItemWrapperStyled
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                >
                  <TestimonialsItemImageStyled>
                    <img src={profile} alt={author} />
                  </TestimonialsItemImageStyled>

                  <TestimonialsItemContentStyled>
                    <Separator type="Nano" />
                    <H3 white>{author}</H3>
                    <Separator type="Small" />

                    <Body white>{testimonial}</Body>

                    <Separator type="SemiSmall" />

                    <H4 white>{office}</H4>
                  </TestimonialsItemContentStyled>
                </TestimonialsItemWrapperStyled>
              ))}
            </Slider>
          </TestimonialsWrapperStyled>
        </Col>
      </Row>
    </Grid>
  </Fade>
);

import React from 'react';

import {
  CardContentStyled,
  CardHeaderStyled,
  CardHeaderStyledProps,
  CardWrapperStyled,
  CardWrapperStyledProps,
  CardFooterStyled,
  CardPriceStyled,
  CardListStyled,
  CardListItemStyled,
  CardListItemIconStyled,
  CardListItemTextStyled,
  CardHeaderTopStyled,
  CardBadgeStyled
} from './card.component.style';

type CardHeaderProps = CardHeaderStyledProps;
export const CardHeader: React.FC<CardHeaderProps> = (props) => (
  <CardHeaderStyled {...props}>{props.children}</CardHeaderStyled>
);

CardHeader.defaultProps = {
  kind: 'primary'
};
export const CardFooter = CardFooterStyled;
export const CardHeaderTop = CardHeaderTopStyled;
export const CardBadge = CardBadgeStyled;
export const CardPrice = CardPriceStyled;
export const CardList = CardListStyled;
export const CardListItem = CardListItemStyled;
export const CardListItemIcon = CardListItemIconStyled;
export const CardListItemText = CardListItemTextStyled;

type CardProps = CardWrapperStyledProps;
export class Card extends React.Component<CardProps, undefined> {
  static Header = CardHeader;

  static Content = CardContentStyled;

  static Footer = CardFooter;

  static HeaderTop = CardHeaderTop;

  static Badge = CardBadge;

  static Price = CardPrice;

  static List = CardList;

  static ListItem = CardListItem;

  static ListItemIcon = CardListItemIcon;

  static ListItemText = CardListItemText;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <CardWrapperStyled {...this.props} as={this.props.href ? 'a' : undefined}>
        {this.props.children}
      </CardWrapperStyled>
    );
  }
}

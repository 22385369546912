import styled from 'styled-components';

import { Color, Radius, Spacing } from '@atomic/constants';

export const ContentWrapperStyled = styled.section`
  background-color: ${Color.Gray100};
  padding-bottom: calc(2 * ${Spacing.XLarge});
  margin-bottom: -${Spacing.XXLarge};

  .mySwiper {
    position: relative;
    margin-top: -${Spacing.SemiLarge};

    .swiper-button-prev,
    .swiper-button-next {
      display: inline-block;

      width: ${Spacing.SemiLarge};
      height: ${Spacing.SemiLarge};

      background: ${Color.Gray900};
      border-radius: ${Radius.Small};

      position: absolute;
      top: 39%;
      z-index: 99;
    }

    .swiper-button-prev {
      left: 0;

      &::before {
        content: '';
        position: absolute;
        top: 36%;
        left: 43%;

        display: inline-block;

        height: ${Spacing.Nano};
        width: ${Spacing.Nano};

        border: solid ${Color.White};
        border-width: 0 3px 3px 0;

        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }

    .swiper-button-next {
      right: 0;

      &::before {
        content: '';
        position: absolute;
        top: 36%;
        right: 43%;

        display: inline-block;

        height: ${Spacing.Nano};
        width: ${Spacing.Nano};

        border: solid ${Color.White};
        border-width: 0 3px 3px 0;

        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }

    .swiper-pagination {
      position: absolute;

      bottom: ${Spacing.Nano};
      left: 50%;

      transform: translateX(-50%);

      .swiper-pagination-bullet-active {
        background: ${Color.Gray900};
      }

      span {
        display: inline-block;

        margin: 0 ${Spacing.XNano};

        width: ${Spacing.Small};
        height: ${Spacing.Small};

        background: ${Color.Gray700};

        border-radius: 50%;
      }
    }

    .swiper-slide {
      width: fit-content;
      margin: 0;

      & > div {
        max-width: 100%;
      }
    }
  }
`;

export const ContentMultiProductImageWrapperStyled = styled.div`
  position: relative;

  width: 100%;
  padding-top: 56.25%;

  border-radius: ${Radius.Medium};
  overflow: hidden;

  & > * {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`;

import React, { useEffect, useState } from 'react';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Fade from 'react-reveal/Fade';

import { Col, Grid, Row } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Body, BodyLink, H3 } from '@atomic/atm.typography';

import {
  FooterWrapperStyled,
  FooterCopyStyled
} from './footer.component.style';

interface FooterProps {
  // eslint-disable-next-line no-undef
  data: GatsbyTypes.MarkdownRemarkFrontmatterFooter;
}

export const Footer: React.FC<FooterProps> = ({
  data: { informations, logo, social_media: socialMedia, institutional }
}) => {
  const [year, setYear] = useState<number>();

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <Fade bottom distance="10vw" duration={600}>
      <FooterWrapperStyled>
        <Grid>
          <Row>
            {informations && (
              <Col xs={12} sm={8} md={12} lg={5}>
                <Separator type="Large" />
                <H3>Informações</H3>

                <Separator type="Medium" />

                <Body>{informations.company_name}</Body>

                <Separator type="Small" />

                <Body>{informations.cnpj}</Body>

                <Separator type="Small" />

                <Body>{informations.address}</Body>
              </Col>
            )}

            <Col xs={6} sm={4} md={4} lg={2}>
              <Separator type="Large" />
              <H3>Navegação</H3>
              <Separator type="SemiSmall" />

              <BodyLink to="#content-section">Cursos</BodyLink>
              <BodyLink to="#testimonials">Depoimentos</BodyLink>
              <BodyLink to="#how-it-works">Como Funciona</BodyLink>
              {process.env.GATSBY_SHOW_PLAN === 'true' && (
                <BodyLink to="#plans">Planos</BodyLink>
              )}
            </Col>

            {socialMedia && (
              <Col xs={6} sm={6} md={4} lg={2}>
                <Separator type="Large" />
                <H3>Redes sociais</H3>
                <Separator type="SemiSmall" />

                <OutboundLink
                  href={socialMedia.facebook_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </OutboundLink>
                <OutboundLink
                  href={socialMedia.instagram_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </OutboundLink>
                {/* <a
                  href={socialMedia.twitter_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
                <a
                  href={socialMedia.youtube_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube
                </a> */}
              </Col>
            )}

            {institutional && (
              <Col xs={12} sm={6} md={4} lg={3}>
                <Separator type="Large" />
                <H3>Institucional</H3>
                <Separator type="SemiSmall" />

                <OutboundLink
                  href={institutional.privacy_policy_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de privacidade
                </OutboundLink>
                <OutboundLink
                  href={institutional.terms_of_use_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos de uso
                </OutboundLink>
                {/* <a
                  href={institutional.frequently_asked_questions_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dúvidas frequentes
                </a>
                <a
                  href={institutional.contact_us_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Contate-nos
                </a> */}
              </Col>
            )}
          </Row>
        </Grid>
        <FooterCopyStyled>
          <Grid>
            <Row>
              <Col xs={12} sm={12} md={2} lg={2}>
                <img src={logo} alt="Logo footer" />
              </Col>

              <Col xs={12} sm={12} md={10} lg={10} hAlign="flex-end">
                <Body>
                  Copyright © {year || new Date().getFullYear()} | GenteLab -
                  Todos os direitos reservados
                </Body>
              </Col>
            </Row>
          </Grid>
        </FooterCopyStyled>
      </FooterWrapperStyled>
    </Fade>
  );
};

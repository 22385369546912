import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Col } from '@atomic/atm.grid';
import { Spacing } from '@atomic/constants';

export const HeroWrapperStyled = styled(motion.div)`
  position: relative;
`;

export const HeroContent = styled.div`
  z-index: 1;

  @media (max-width: 991px) {
    h1 {
      margin-top: -${Spacing.SemiLarge};
    }
  }
`;

export const HeroImage = styled(Col)`
  position: absolute;
  top: 0;
  right: -8.33%;

  width: 100%;

  z-index: -1;

  img {
    width: 100%;
  }

  @media (max-width: 991px) {
    & {
      position: unset;
      margin-top: ${Spacing.Large};

      div {
        margin-bottom: -${Spacing.Large};
      }
    }
  }
`;

/* eslint-disable object-curly-newline */
import styled from 'styled-components';

import {
  Color,
  Spacing,
  BrandColor,
  FontWeight,
  LineHeight,
  FontFamily
} from '@atomic/constants';

export const FooterWrapperStyled = styled.footer`
  padding: 0;
  padding-top: ${Spacing.Medium};

  background-color: ${Color.White};

  a {
    display: block;

    width: 100%;
    margin-top: ${Spacing.Nano};

    padding: ${Spacing.XNano} 0;

    line-height: ${LineHeight.Medium};

    color: ${BrandColor.Brand500};

    font-family: ${FontFamily.Primary};
    font-size: 1em;

    text-decoration: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: ${FontWeight.Medium};
    cursor: pointer;
  }
`;

export const FooterCopyStyled = styled.div`
  display: block;
  margin-top: ${Spacing.Large};
  border-top: 2px solid ${Color.Gray200};
  padding: ${Spacing.SemiLarge} 0;

  @media (max-width: 767px) {
    p {
      margin-top: ${Spacing.Small};
      align-self: flex-start;
    }
  }
  img {
    max-width: 100%;
    max-height: ${Spacing.Large};
  }
`;

import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Fade from 'react-reveal/Fade';

import { Button } from '@atomic/atm.button';
import { Col, Grid, Row } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { H2, Body, H3, H6, H4 } from '@atomic/atm.typography';
import { Card } from '@atomic/mol.card/card.component';

import {
  PricingWrapperStyled,
  PricingCardsWrapperStyled
} from './pricing.section.style';

interface PricingSectionProps {
  // eslint-disable-next-line no-undef
  data: GatsbyTypes.MarkdownRemarkFrontmatterPlans;
}

export const PricingSection: React.FC<PricingSectionProps> = ({
  data: { title, description, plans }
}) => (
  <PricingWrapperStyled id="plans">
    <Fade bottom distance="10vw" duration={600}>
      <Grid>
        {title && description && (
          <Row hAlign="center">
            <Col xs={12} sm={10} md={8} lg={7} xl={6}>
              <Fade bottom distance="10vw" duration={600}>
                <H2 center>{title}</H2>
                <Separator type="Small" />
                <Body textAlign="center">{description}</Body>
                <Separator type="Medium" />
              </Fade>
            </Col>
          </Row>
        )}

        {plans && (
          <Row hAlign="center">
            <PricingCardsWrapperStyled>
              {plans.map(
                ({
                  plan_name: planName,
                  plan_subtitle: planSubtitle,
                  plan_type: planType,
                  price,
                  installments,
                  description,
                  features,
                  is_recommended: isRecommended,
                  url
                }) =>
                  planType !== 'Lifelong' ? (
                    <Col xs={12} sm={12} md={8} lg={6} xl={4} flexCol>
                      <Separator type="Medium" />

                      <Card>
                        <Card.Header>
                          {isRecommended && (
                            <Card.Badge>Recomendado</Card.Badge>
                          )}

                          <Card.HeaderTop>
                            {planName && <H3>{planName}</H3>}

                            <Card.Price>
                              {price && (
                                <H3>
                                  {price.toLocaleString('pt-BR', {
                                    currency: 'BRL',
                                    style: 'currency',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </H3>
                              )}
                              {planType === 'Monthly' ? (
                                <Body>/ mês</Body>
                              ) : (
                                <Body>/ ano</Body>
                              )}
                            </Card.Price>
                          </Card.HeaderTop>
                          {planSubtitle && <H4>{planSubtitle}</H4>}
                        </Card.Header>

                        <Card.Content>
                          {description && <H6>{description}</H6>}

                          {features && (
                            <Card.List>
                              {features.map(({ feature }) => (
                                <Card.ListItem>
                                  <Card.ListItemIcon>
                                    <span />
                                  </Card.ListItemIcon>

                                  {feature && (
                                    <Card.ListItemText>
                                      {feature}
                                    </Card.ListItemText>
                                  )}
                                </Card.ListItem>
                              ))}
                            </Card.List>
                          )}
                        </Card.Content>

                        <Card.Footer>
                          <OutboundLink href={url}>
                            <Button kind="callToAction" expanded>
                              Assinar Plano
                            </Button>
                          </OutboundLink>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ) : (
                    <Col xs={12} sm={12} md={8} lg={6} xl={4} flexCol>
                      <Separator type="Medium" />

                      <Card>
                        <Card.Header>
                          {isRecommended && (
                            <Card.Badge>Recomendado</Card.Badge>
                          )}

                          <Card.HeaderTop>
                            {planName && <H3>{planName}</H3>}
                          </Card.HeaderTop>

                          {price ? (
                            <H6>
                              Valor parcelado: {installments} x R${price}
                            </H6>
                          ) : (
                            <H6>Valor que cabe no seu bolso</H6>
                          )}
                        </Card.Header>

                        <Card.Content>
                          {description && <H6>{description}</H6>}

                          {features && (
                            <Card.List>
                              {features.map(({ feature }) => (
                                <Card.ListItem>
                                  <Card.ListItemIcon>
                                    <span />
                                  </Card.ListItemIcon>

                                  {feature && (
                                    <Card.ListItemText>
                                      {feature}
                                    </Card.ListItemText>
                                  )}
                                </Card.ListItem>
                              ))}
                            </Card.List>
                          )}
                        </Card.Content>

                        <Card.Footer>
                          <OutboundLink href={url}>
                            <Button kind="callToAction" expanded>
                              Assinar Plano
                            </Button>
                          </OutboundLink>
                        </Card.Footer>
                      </Card>
                    </Col>
                  )
              )}
            </PricingCardsWrapperStyled>
          </Row>
        )}
      </Grid>
    </Fade>
  </PricingWrapperStyled>
);

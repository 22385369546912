import React from 'react';

import { Link } from 'gatsby';

import { Button } from '@atomic/atm.button';
import { Col, Grid, Row } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Body, H1 } from '@atomic/atm.typography';

import {
  HeroContent,
  HeroImage,
  HeroWrapperStyled
} from './hero.section.style';

interface HeroSectionProps {
  // eslint-disable-next-line no-undef
  data: GatsbyTypes.MarkdownRemarkFrontmatterHero;
}

export const HeroSection: React.FC<HeroSectionProps> = ({
  data: { title, hero_image: heroImage, description }
}) => (
  <Grid>
    <Row>
      <HeroWrapperStyled>
        <Separator type="SemiLarge" />
        <HeroContent>
          {title && (
            <Col xs={12} sm={12} md={12} lg={8} xl={7}>
              <H1>{title}</H1>
              <Separator type="SemiSmall" />
            </Col>
          )}

          {description && (
            <Col xs={10} sm={10} md={10} lg={7} xl={6}>
              <Body>{description}</Body>
              <Separator type="SemiLarge" />
              {process.env.GATSBY_SHOW_PLAN === 'true' && (
                <Link to="/#plans">
                  <Button kind="callToAction">Ver Planos</Button>
                </Link>
              )}
            </Col>
          )}
        </HeroContent>

        {heroImage && (
          <HeroImage xs={12} sm={12} md={12} lg={6} xl={7}>
            <img src={heroImage} alt="people learning" />
          </HeroImage>
        )}

        {process.env.GATSBY_SHOW_PLAN === 'true' ? (
          <Separator type="Large" />
        ) : (
          <Separator type="XXLarge" />
        )}
      </HeroWrapperStyled>
    </Row>
  </Grid>
);

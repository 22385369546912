/* eslint-disable object-curly-newline */
import styled from 'styled-components';

import { H6 } from '@atomic/atm.typography';
import {
  Color,
  Radius,
  Spacing,
  Shadow,
  FontFamily,
  FontSize,
  LineHeight,
  FontWeight
} from '@atomic/constants';

enum CardKind {
  primary = 'primary'
}

type CardColor = {
  [key in CardKind]: string;
};

const cardColor: CardColor = {
  [CardKind.primary]: Color.White
};

export interface CardHeaderStyledProps {
  kind?: keyof typeof CardKind;
}

export const CardHeaderStyled = styled.div<CardHeaderStyledProps>`
  position: relative;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;

  padding: ${Spacing.Medium};
  padding-bottom: 0;

  text-align: left;

  background-color: ${(props: CardHeaderStyledProps) => cardColor[props.kind]};
  color: ${Color.Gray900};
  border-radius: ${Radius.Large} ${Radius.Large} 0 0;

  h6 {
    display: block;

    width: 100%;

    margin-top: ${Spacing.Nano};
  }
`;

export const CardHeaderTopStyled = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: ${Spacing.Small};
`;

export const CardBadgeStyled = styled.span`
  display: block;
  width: fit-content;
  background-color: ${Color.Primary};
  padding: 10px 20px;
  border-radius: ${Radius.Small};

  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XSmall};
  line-height: ${LineHeight.XSmall};
  color: ${Color.White};
  font-weight: ${FontWeight.Medium};

  position: absolute;
  right: 0;
  top: -${Spacing.Small};
`;

export const CardPriceStyled = styled.span`
  display: flex;
  align-items: baseline;

  p,
  h3 {
    margin-left: 4px;
  }
`;

interface CardContentStyledProps {
  hAlign?: string;
  vAlign?: string;
}

export const CardContentStyled = styled.div<CardContentStyledProps>`
  position: relative;
  padding: ${Spacing.Medium};
  padding-bottom: ${Spacing.SemiLarge};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${(props: CardContentStyledProps) =>
    props.hAlign || 'flex-start'};
  justify-content: ${(props: CardContentStyledProps) =>
    props.vAlign || 'flex-start'};
`;

export const CardListStyled = styled.div`
  margin-top: ${Spacing.Medium};
`;

export const CardListItemStyled = styled.div`
  display: flex;
  flex: 1;

  & + & {
    margin-top: ${Spacing.SemiSmall};
  }
`;
export const CardListItemIconStyled = styled.span`
  position: relative;
  flex-direction: column;
  flex: 0 0 ${Spacing.SemiSmall};
  min-width: ${Spacing.SemiSmall};
  height: ${Spacing.SemiSmall};
  background-color: ${Color.Gray200};
  border-radius: 50%;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.6);
    height: 12px;
    width: 6px;
    border-bottom: 3px solid ${Color.Gray700};
    border-right: 3px solid ${Color.Gray700};
  }
`;

export const CardListItemTextStyled = styled(H6)`
  margin-left: ${Spacing.Nano};
  display: flex;
  flex: 1;
`;

export interface CardWrapperStyledProps {
  href?: string;
  hidden?: boolean;
}

export const CardWrapperStyled = styled.div<CardWrapperStyledProps>`
  position: relative;
  border-radius: ${Radius.Medium};
  background-color: ${Color.White};
  box-shadow: ${Shadow.Primary};
  width: 100%;
  flex: 1;
  cursor: ${(props: CardWrapperStyledProps) =>
    props.href ? 'pointer' : 'auto'};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const CardFooterStyled = styled.div`
  background-color: ${Color.White};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.Medium};
  padding-top: 0;
  border-radius: 0 0 ${Radius.Large} ${Radius.Large};
`;

import styled from 'styled-components';

import { Color, Spacing } from '@atomic/constants';

interface HowItWorksImageStyledProps {
  reverse?: boolean;
}

export const HowItWorksImageStyled = styled.div<HowItWorksImageStyledProps>`
  position: relative;
  display: flex;

  margin: ${(props) =>
    props.reverse ? `0 0 0 ${Spacing.Medium}` : `0 ${Spacing.Medium} 0 0`};

  width: calc(100% - ${Spacing.Medium});
  padding-top: 80%;

  overflow: hidden;

  & > * {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @media (max-width: 767px) {
    & {
      width: 100%;
      margin: 0;
    }
  }
`;

interface HowItWorksContentStyledProps {
  reverse?: boolean;
}

export const HowItWorksContentStyled = styled.div<HowItWorksContentStyledProps>`
  margin: ${(props) =>
    props.reverse ? `0 ${Spacing.Medium} 0 0` : `0 0 0 ${Spacing.Medium}`};

  width: calc(100% - ${Spacing.Medium});

  h5 {
    color: ${Color.Primary};
  }

  @media (max-width: 767px) {
    & {
      width: 100%;
      margin: 0;
    }
  }
`;

import styled from 'styled-components';

import { Col } from '@atomic/atm.grid';
import { Color, Spacing, Radius } from '@atomic/constants';

export const TestimonialsWrapperStyled = styled(Col)`
  position: relative;
  margin-top: ${Spacing.XLarge};

  display: block;

  width: 100%;

  padding-top: calc(5 * ${Spacing.SemiLarge});
  padding-bottom: calc(4 * ${Spacing.Large});

  background-color: ${Color.Gray900};
  border-radius: ${Radius.Large};

  .sliderStyled {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;

    transform: translate(-50%, -50%);

    .carousel-initialized {
      height: calc(2 * (${Spacing.XXLarge} + 0.5rem));
    }

    .carousel-item {
      opacity: 0;
      transition: opacity 0.1s ease-out;

      &.active {
        opacity: 1;
      }
    }

    .carousel-dots {
      margin-top: ${Spacing.SemiSmall};

      li {
        margin: 0;

        &.carousel-dots-active {
          button::before {
            color: ${Color.White};
          }
        }

        button::before {
          color: ${Color.Gray700};
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 991px) {
    & {
      padding-top: calc(3 * ${Spacing.XLarge});
      padding-bottom: calc(3 * ${Spacing.XLarge});

      .carousel-initialized {
        height: calc(3 * (${Spacing.XXLarge} + 0.5rem)) !important;
      }
    }
  }

  @media (max-width: 767px) {
    & {
      padding-top: calc(3 * ${Spacing.XLarge});
      padding-bottom: calc(3 * ${Spacing.XLarge});

      .carousel-initialized {
        height: calc(3.25 * (${Spacing.XXLarge} + 0.5rem)) !important;
      }
    }
  }

  @media (max-width: 575px) {
    & {
      padding-top: calc(3 * ${Spacing.XLarge});
      padding-bottom: calc(3 * ${Spacing.XLarge});

      .carousel-initialized {
        height: calc(3.5 * (${Spacing.XXLarge} + 0.5rem)) !important;
      }

      .carousel-dots {
        margin-top: ${Spacing.SemiSmall};
      }
    }
  }
`;
export const TestimonialsItemWrapperStyled = styled(Col)`
  display: flex !important;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;

  outline: none !important;
  box-shadow: none !important;
  border: none;

  @media (max-width: 767px) {
    & {
      display: block !important;
    }
  }
`;
export const TestimonialsItemImageStyled = styled.div`
  display: inline-flex;
  flex-direction: column;

  width: calc(3 * ${Spacing.Medium});
  height: calc(3 * ${Spacing.Medium});

  margin-right: ${Spacing.Medium};

  background-color: #e4e4e4;
  border-radius: 50%;

  position: relative;
  overflow: hidden;

  & > * {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @media (max-width: 767px) {
    & {
      display: block;

      margin-right: 0;
      margin-bottom: ${Spacing.Small};
    }
  }
`;
export const TestimonialsItemContentStyled = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(100% - ${Spacing.XLarge});

  text-align: left;

  @media (max-width: 767px) {
    & {
      width: 100%;
    }
  }
`;

/* eslint-disable no-undef */
import React from 'react';

import Fade from 'react-reveal/Fade';

import { Grid, Row, Col } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Body, H2, H3 } from '@atomic/atm.typography';

import {
  HowItWorksImageStyled,
  HowItWorksContentStyled
} from './how-it-works.section.style';

interface HowItWorksSectionProps {
  // eslint-disable-next-line camelcase
  data: GatsbyTypes.MarkdownRemarkFrontmatterHow_it_works;
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = ({
  data: { title, description, sections }
}) => (
  <Grid id="how-it-works">
    {title && description && (
      <Row hAlign="center">
        <Col xs={12} sm={10} md={8} lg={7} xl={6}>
          <Fade bottom cascade distance="10vh" duration={600}>
            <Separator type="XXLarge" />
            <H2 center>{title}</H2>
            <Separator type="Small" />

            <Body textAlign="center">{description}</Body>
            <Separator type="Large" />
          </Fade>
        </Col>
      </Row>
    )}

    {sections.map(({ image, title, name }, i, a) => {
      if (i % 2 === 0) {
        return (
          <>
            <Row hAlign="center">
              {image && title && (
                <Col xs={12} sm={10} md={6} lg={6} xl={5}>
                  <Fade bottom cascade distance="10vw" duration={600}>
                    <HowItWorksImageStyled>
                      <img src={image} alt={title} />
                    </HowItWorksImageStyled>
                  </Fade>
                </Col>
              )}

              {title && name && (
                <Col xs={12} sm={10} md={6} lg={6} xl={5}>
                  <HowItWorksContentStyled>
                    <Fade bottom cascade distance="10vw" duration={600}>
                      <Separator type="SemiLarge" />
                      <H3>{title}</H3>
                      <Separator type="SemiSmall" />

                      <Body>{name}</Body>
                    </Fade>
                  </HowItWorksContentStyled>
                </Col>
              )}
            </Row>

            {a.length - 1 !== i && <Separator type="XLarge" />}
          </>
        );
      }

      return (
        <>
          <Row reverse hAlign="center">
            {image && title && (
              <Col xs={12} sm={10} md={6} lg={6} xl={5}>
                <Fade bottom cascade distance="10vw" duration={600}>
                  <HowItWorksImageStyled reverse>
                    <img src={image} alt={title} />
                  </HowItWorksImageStyled>
                </Fade>
              </Col>
            )}

            {title && name && (
              <Col xs={12} sm={10} md={6} lg={6} xl={5}>
                <Fade bottom cascade distance="10vw" duration={600}>
                  <HowItWorksContentStyled reverse>
                    <Separator type="SemiLarge" />

                    <H3>{title}</H3>
                    <Separator type="SemiSmall" />

                    <Body>{name}</Body>
                  </HowItWorksContentStyled>
                </Fade>
              </Col>
            )}
          </Row>

          <Separator type="XLarge" />
        </>
      );
    })}
  </Grid>
);

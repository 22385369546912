import styled from 'styled-components';

import { Color, Spacing } from '@atomic/constants';

export const PricingWrapperStyled = styled.section`
  padding-top: ${Spacing.XLarge};
  margin-bottom: calc(2 * ${Spacing.XLarge});

  background-color: ${Color.Gray100};
`;

export const PricingCardsWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: -${Spacing.XLarge};

  a,
  button {
    width: 100%;
  }
`;

import React from 'react';

import { graphql } from 'gatsby';

import { GlobalStyled } from '@atomic/atm.globals/global-style';
import { GridStyled } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Footer } from '@atomic/org.footer/footer.component';
import { Header } from '@atomic/org.header';
import { LandingPageTemplate } from '@templates/landing-page';

interface IndexPageProps {
  // eslint-disable-next-line no-undef
  data: GatsbyTypes.getSettingsQuery;
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { footer, header } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <>
      <Header data={header} />
      <Separator type="XXLarge" />
      <LandingPageTemplate />
      <Footer data={footer} />
      <GlobalStyled />
      <GridStyled />
    </>
  );
};

export const pageQuery = graphql`
  query getSettings {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "Settings" } } }) {
      edges {
        node {
          frontmatter {
            header {
              logo
            }
            footer {
              logo
              informations {
                company_name
                cnpj
                address
              }
              social_media {
                facebook_link
                instagram_link
                twitter_link
                youtube_link
              }
              institutional {
                privacy_policy_link
                terms_of_use_link
                frequently_asked_questions_link
                contact_us_link
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

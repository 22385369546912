/* eslint-disable no-undef */
import React from 'react';

import Fade from 'react-reveal/Fade';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { Col, Grid, Row } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Body, H2, H4, H5 } from '@atomic/atm.typography';

import {
  ContentWrapperStyled,
  ContentMultiProductImageWrapperStyled
} from './content.section.style';

interface ContentSectionProps {
  // eslint-disable-next-line camelcase
  data: GatsbyTypes.MarkdownRemarkFrontmatterCourses_section;
}

export const ContentSection: React.FC<ContentSectionProps> = ({
  data: { title, description, courses }
}) => {
  SwiperCore.use([Navigation]);

  return (
    <ContentWrapperStyled id="content-section">
      <Grid>
        {title && description && (
          <Row hAlign="center">
            <Col xs={12} sm={10} md={8} lg={7} xl={6}>
              <Fade bottom distance="10vw" duration={600}>
                <Separator type="XLarge" />
                <H2 center>{title}</H2>
                <Separator type="Small" />

                <Body textAlign="center">{description}</Body>
              </Fade>
            </Col>
          </Row>
        )}

        <Separator type="Large" />

        <Fade bottom distance="10vw" duration={600}>
          {courses && (
            <Row>
              <Swiper
                slidesPerView={1}
                navigation
                breakpoints={{
                  575: {
                    slidesPerView: 2
                  },
                  992: {
                    slidesPerView: 3
                  },
                  1200: {
                    slidesPerView: 4
                  }
                }}
                loop
                className="mySwiper"
              >
                {courses.map(({ image, title, description }) => (
                  <SwiperSlide>
                    <Col lg={4} xl={3}>
                      <Separator type="Large" />

                      <ContentMultiProductImageWrapperStyled>
                        <img src={image} alt={title} />
                      </ContentMultiProductImageWrapperStyled>
                      <Separator type="Nano" />

                      <H4>{title}</H4>

                      <Separator type="XNano" />

                      <H5>{description}</H5>
                    </Col>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Row>
          )}
        </Fade>
      </Grid>
    </ContentWrapperStyled>
  );
};
